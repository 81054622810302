.socialMediaBadges a {
  @apply text-white;
}

.socialMediaBadges a:hover {
  @apply text-white;
}

.socialMediaBadges a span {
  display: none;
}

.socialMediaBadges a:not(:last-child) {
  margin-right: 1em;
}

.footerBottom ul li {
  margin-right: 1em;
}

.footerWave {
  background-image: url(./footerWave.svg);
}
