.tooltip {
  display: flex;
  margin-right: 6px;
  position: relative;
}

.tooltip .tooltipText {
  @apply bg-deep-sea text-white rounded-lg;
  max-width: 280px;
  padding: 6px;
  position: absolute;
  text-align: center;
  top: 100%;
  visibility: hidden;
  z-index: 1;
}

.tooltip:hover .tooltipText {
  visibility: visible;
}
