.default {
  @apply my-8;
  width: 540px;
}

.lg {
  @apply my-8;
  width: 800px;
}

.xl {
  @apply my-8;
  width: 90vw;
  /* This helps avoid the bottom bar on iOS */
  min-height: 80vh;
}

@screen md {
  .xl {
    min-height: 90vh;
  }
}

.full {
  height: 100vh;
  min-height: 100vh;
}

@screen sm {
  .full {
    padding-bottom: 0px;
  }
}
