@import 'contrib/normalize.css';
@import 'contrib/react-calendar.css';
@import 'contrib/time-picker.css';

.modal-open {
  overflow: hidden;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

small {
  font-size: 0.8em;
}

form button {
  display: block;
  margin: 1em 0;
}

.toggle-switch-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

input:disabled {
  @apply bg-lake-fog;
  cursor: not-allowed;
}

dl {
  display: grid;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.5em;
  grid-template-columns: max-content auto;
  margin: 0 0 2em 0;
}

dt {
  @apply text-midnight;
  grid-column-start: 1;
}

dd {
  grid-column-start: 2;
  margin: 0;
}

input:focus,
select:focus {
  outline: none;
}

.image-gallery-slide .image-gallery-image {
  width: auto;
  max-width: 100%;
}

/* Stripe styles */
.StripeElement {
  -webkit-appearance: none;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #dde3e8;
  box-shadow: none !important;
  height: auto;
  padding: 0.75rem;
  transition: 200ms;
  width: 100%;
}

.StripeElement--focus {
  border-color: #3f5162;
}

.StripeElement--invalid {
  border-color: #cc1e15;
}

.react-calendar-heatmap .color-scale-1 {
  fill: #bee3f8;
}
.react-calendar-heatmap .color-scale-2 {
  fill: #63b3ed;
}
.react-calendar-heatmap .color-scale-3 {
  fill: #3182ce;
}
.react-calendar-heatmap .color-scale-4 {
  fill: #2c5282;
}

/* Mapbox override for popup */

.mapboxgl-popup-content {
  padding: 2px;
}
