.main {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  height: 128px;
  margin: 0 auto;
  position: relative;
  width: 128px;
  margin-bottom: 4rem;
}

.ldsring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-width: 8px;
  border-style: solid;
  border-radius: 50%;
  animation: ldsring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.ldsring div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsring div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes ldsring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mainMini {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ldsringMini {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
}
.ldsringMini div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  margin: 2px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  animation: ldsring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.ldsringMini div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsringMini div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsringMini div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes ldsringMini {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
